@font-face {
  font-family: "Lato";
  src: url("../public/fonts/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Lato";
  src: url("../public/fonts/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content {
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ttip {
  padding: 12px;
  background: #303359;
  border-radius: 16px;
  font-size: 10px;
  font-family: "Lato", sans-serif;
  color: #fff;
}

h2 {
  font-size: 38px;
}

.accented {
  color: #5c629d;
  font-weight: 700;
}

.logo-animation {
  animation-name: logo;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

.landing-strip-animation {
  animation-name: landing-strip;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: step-start;
}

.rotation {
  animation-name: rotation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.pulsating {
  animation: pulsation;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-timing-function: linear;
}

@keyframes pulsation {
  0% {
    box-shadow: 0 0 0 0 rgba(253, 205, 133, 0.56);
  }
  25% {
    box-shadow: 0 0 0 5px rgba(253, 205, 133, 0.56);
  }
  50% {
    box-shadow: 0 0 0 15px rgba(253, 205, 133, 0.56);
  }
  75% {
    box-shadow: 0 0 0 5px rgba(253, 205, 133, 0.56);
  }
  100% {
    box-shadow: 0 0 0 0 #FFA726;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes landing-strip {
  0% {
    transform: translateX(-29px);
  }
  20% {
    transform: translateX(-14.5px);
  }
  40% {
    transform: translateX(0px);
  }
  60% {
    transform: translateX(14.5px);
  }
  80% {
    transform: translateX(29px);
  }
  100% {
    transform: translateX(-29px);
  }
}

@keyframes logo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

