.mantine-NumberInput-label,
.mantine-Textarea-label,
.mantine-TextInput-label,
.mantine-Select-label,
.mantine-MultiSelect-label {
  z-index: 1;
  position: relative;
  padding: 0 2px;
  backdrop-filter: blur(4px);
  font-family: Lato, sans-serif;
}

.request-demo-input .mantine-TextInput-label {
  backdrop-filter: none;
  background: #fff;
}

.mantine-TextInput-invalid, .mantine-Select-invalid, .mantine-NumberInput-invalid {
  border-color: #C1292E;
}

.mantine-TextInput-error, .mantine-Select-error, .mantine-NumberInput-error {
  color: #C1292E !important;
}

.mantine-TextInput-input:disabled {
  color: #141414;
}

.mantine-Checkbox-input:checked {
  background-color: #6065a8;
  border-color: #6065a8;
}

input:focus,
textarea:focus,
.mantine-MultiSelect-input:focus,
.mantine-MultiSelect-input:focus-within {
  outline: none;
  border-color: #6065a8 !important;
}

/* Shepherd */
.shepherd-button {
  border: none;
  cursor: pointer;
}
.shepherd-button:disabled {
  cursor: not-allowed;
}
.shepherd-footer {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.shepherd-footer .shepherd-button:last-child {
  margin-right: 0;
}
.shepherd-cancel-icon {
  background: transparent;
  border: none;
  color: hsla(0, 0%, 50.2%, 0.75);
  font-size: 2em;
  cursor: pointer;
  font-weight: 400;
  margin: 0;
  padding: 0;
  transition: color 0.5s ease;
}
.shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, 0.75);
}
.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  color: hsla(0, 0%, 50.2%, 0.75);
}
.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, 0.75);
}
.shepherd-title {
  /*color: rgba(0, 0, 0, 0.75);*/
  display: flex;
  /*font-size: 1rem;*/
  /*font-weight: 400;*/
  flex: 1 0 auto;
  /*margin: 0;*/
  /*padding: 0;*/
  font-family: "Lato", sans-serif;
  font-size: 22px;
  line-height: 33px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  margin: 0;
}
.shepherd-header {
  margin-bottom: 4px;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  line-height: 2em;
  /*padding: 0.75rem 0.75rem 0;*/
}
.shepherd-has-title .shepherd-content .shepherd-header {
  /*background: #e6e6e6;*/
  /*padding: 1em;*/
  /*font-family: "Lato", sans-serif;*/
}
.shepherd-text {
  /*color: rgba(0, 0, 0, 0.75);*/
  /*font-size: 1rem;*/
  /*line-height: 1.3em;*/
  /*padding: 0.75em;*/
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.75);
}
.tour-button-primary {
  background: #6065a8;
  border-radius: 16px;
  padding: 12px 34px;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
}
.tour-button-cancel {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #636363;
  background: #fff;
}
.tour-button-prev {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: rgba(99, 99, 99, 1);
  background: #fff;
  color: #6065a8;
}
.shepherd-text p {
  margin-top: 0;
}
.shepherd-text p:last-child {
  margin-bottom: 0;
}
.shepherd-content {
  border-radius: 16px;
  outline: none;
  padding: 24px;
}
.shepherd-element {
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  opacity: 0;
  outline: none;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
  border-radius: 16px;
}
/* have to override modal positioning  */
.shepherd-element.left-padding {
  left: calc(20px + 16px) !important;
}
.shepherd-element.right-padding {
  right: 16px !important;
}
.shepherd-enabled.shepherd-element {
  opacity: 1;
  visibility: visible;
}
.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
.shepherd-element,
.shepherd-element *,
.shepherd-element :after,
.shepherd-element :before {
  box-sizing: border-box;
}
.shepherd-arrow,
.shepherd-arrow:before {
  /*display: none;*/
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: -1;
}
.shepherd-arrow:before {
  content: "";
  transform: rotate(45deg);
  background: #fff;
}
.shepherd-element[data-popper-placement^="top"] > .shepherd-arrow {
  bottom: -8px;
}
.shepherd-element[data-popper-placement^="bottom"] > .shepherd-arrow {
  top: -8px;
}
.shepherd-element[data-popper-placement^="left"] > .shepherd-arrow {
  right: -8px;
}
.shepherd-element[data-popper-placement^="right"] > .shepherd-arrow {
  left: -8px;
}
.shepherd-element.shepherd-centered > .shepherd-arrow {
  opacity: 0;
}
.shepherd-element.shepherd-has-title[data-popper-placement^="bottom"]
  > .shepherd-arrow:before {
  background-color: #e6e6e6;
}
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none;
}
.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
  width: 100vw;
  z-index: 9997;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: 0.5;
  transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all;
}

.sub-headline {
  margin-top: 4px;
  margin-bottom: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 33px;
  color: rgba(64, 64, 64, 0.7);
}

.tour-headline {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
}
